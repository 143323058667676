var breadcrumbElement = null;

function getCurrentPath() {
  return window.location.href;
}

function getPathName(href) {
  var l = document.createElement("a");
  l.href = href;
  return l.pathname;
}

function getBreadcrumbs() {
  return JSON.parse(localStorage.breadcrumbs);
}

function getPersistingBreadcrumbs() {
  var crumbs = getBreadcrumbs();
  return crumbs.filter(function(c) {return c.persist;})
}

function setBreadcrumbs(crumbs) {
  localStorage.breadcrumbs = JSON.stringify(crumbs);
}

function clearBreadcrumbTrail() {
  localStorage.setItem('breadcrumbs', JSON.stringify([]));
}

function setTrail(breadcrumbTrail) {

  clearBreadcrumbTrail();

  $.each(breadcrumbTrail, function( index, value ) {
    appendTrail(value.name, value.path);
  });

  renderBreadcrumbs();
}

function startTrail(name) {
  clearBreadcrumbTrail();
  appendTrail(name);
}

function appendTrail(name, persistInTrail) {

  if(typeof persistInTrail == 'undefined') {
    persistInTrail = true;
  }

  var path = getCurrentPath();
  var crumbs = getPersistingBreadcrumbs();

  var match = crumbs.find(function(c) {
    return getPathName(path) == getPathName(decodeURIComponent(c.path));
  }); 

  if(typeof match != 'undefined') {
    navigateTo(match.index);
  }
  else {
    var newCrumbData = {name: name, path: encodeURIComponent(path), index: crumbs.length + 1, persist: persistInTrail};

    crumbs.push(newCrumbData);

    setBreadcrumbs(crumbs);
  }

  return false;
}

function updateCurrentBreadcrumb() {
  var crumbs = getBreadcrumbs();

  if(crumbs.length) {
    var path = getCurrentPath();
    var lastCrumb = crumbs[crumbs.length - 1];
    lastCrumb.path = encodeURIComponent(path);
    setBreadcrumbs(crumbs);
  }
}

function navigateTo(crumbIndex) {
  var crumbs = getBreadcrumbs();
  crumbs = crumbs.slice(0, crumbIndex);
  setBreadcrumbs(crumbs);
}

function sliceLastBreadcrumb() {
  var crumbs = getBreadcrumbs();
  crumbs.splice(-1,1);
  setBreadcrumbs(crumbs);
}

function renderBreadcrumbs() {

  var breadcrumbHtml = '';

  var crumbs = getBreadcrumbs();

  if(crumbs.length > 1) {
    
    breadcrumbHtml = '<ol class="breadcrumb breadcrumb-outline">';

    var lastIndex = crumbs.length - 1;

    $.each(crumbs, function( index, value ) {

      var crumbName;

      if(value.name.length > 35) {
        crumbName = value.name.substring(0, 35) + "...";
      }
      else {
        crumbName = value.name;
      }

      if(index < lastIndex) {          
        breadcrumbHtml += '<li><a href="' + decodeURIComponent(value.path) + '"data-index="' + value.index + '" class="breadcrumb-trail">' + crumbName + '</a></li>';
      }
      else {
        breadcrumbHtml += '<li class="active">' + crumbName + '</li>';
      }
    });

    breadcrumbHtml += '</ol>';
  }
  else if(crumbs.length == 1) {
    breadcrumbHtml = '<p class="dashboard-navigation-title">' + crumbs[0].name + '</p>';
  }

  $(breadcrumbElement).html(breadcrumbHtml); 
}

function listenToBreadcrumbs() {
  var self = this;

  $(breadcrumbElement).off().on("click", "a.breadcrumb-trail", function(e){
    var crumbIndex = $(this).data("index");
    navigateTo(crumbIndex);
  });     
}

function getBreadcrumbName(element) {
  var name;

  if($(element).data("breadcrumb-name")) {            
    name = $(element).data("breadcrumb-name");
  }
  else {
    name = $(element).text();
  }

  return name;    
}

document.addEventListener("turbolinks:load", function() {

  if($("#breadcrumbs_section").length) {
    breadcrumbElement = "#breadcrumbs_section";
    renderBreadcrumbs();
    listenToBreadcrumbs();
  }

  $(".slice-last-breadcrumb").on("click", function(e){
    sliceLastBreadcrumb();
    return true;
  });

});

window.clearBreadcrumbTrail = clearBreadcrumbTrail;
window.startTrail = startTrail;
window.appendTrail = appendTrail;
window.updateCurrentBreadcrumb = updateCurrentBreadcrumb;