var pollJobStatus = job_id => $.get("/events/sidekiq_job_status.json", {"job_id" : job_id}, function(data) {

  if ((data["job_status"] === "queued") || (data["job_status"] === "working")) {
    return setTimeout(pollJobStatus, 1000, data["job_id"]);
  } else {
    $('#loader-overlay').fadeOut();
    return location.reload();
  }
});

const submitFormForSelection = function(form) {
  const checked = $('input:checkbox.selectable:checked').map(function() { return this.value; }).get();

  if (checked.length > 0) {
    $("#events_user_ids").val(checked);
    $(form).submit();
  }

  return false;  
};

const ready = function() {
  $("#events_user_action").off().on('click', function(e) {
    if ($("#user_id").val() === "") { return; }
    return submitFormForSelection("#assign_form");
  });

  $("#schedule_email_option").off().on("change", e => submitFormForSelection("#schedule_form")); 

  return $(document).on('click', '.invite-events-user', function() {
    const url = "/events_users/" + $(this).data("events-user-id") + "/prepare-invite";
    $.ajax({
      type: 'GET',
      url
    });
          
    return false;
  });    
};

$(document).on('turbolinks:load', ready);
var root = typeof exports !== 'undefined' && exports !== null ? exports : this;