function globalInit() {
	calendarLinkOut();
	menuToggle();
	initTooltips();
}

function viewDateOnCalendar(date) {
	history.pushState(null, document.title, "/day-events/" + date );

	$.ajax({
	  type: "GET",
	  url: "/day-events/" + date + '.js'
	});	
}


function calendarLinkOut(){
	$('.has-events').click(function(){

		$('.active-date').removeClass('active-date');
		$(this).addClass('active-date');

		viewDateOnCalendar($(this).data('link-out'));
	})
}

function menuToggle(){

	$('.nav-link-wrapper').click(function(){

		if($(this).hasClass('underline')){
			$('.underline').removeClass('underline')
			$('.open-menu').removeClass('open-menu')
			$('active-sub-menu').removeClass('active-sub-menu')
			$('.fa.fa-caret-down').removeClass('active-sub-menu')

		} else {
			$('.open-menu').removeClass('open-menu')
			$('.underline').removeClass('underline')
			$('active-sub-menu').removeClass('active-sub-menu')
			$('.fa.fa-caret-down').removeClass('active-sub-menu')
			

			$(this).addClass('underline');
			$(this).next().addClass('open-menu')
			$(this).find('.fa.fa-caret-down').addClass('active-sub-menu')
		}
	})

}

function initTooltips(){
  $('[data-toggle="tooltip"]').tooltip()
}

document.addEventListener("turbolinks:load", function() {
	globalInit();
	$('document').eventsInit();
	dashboardInit();
	$('select').selectpicker();
	$('input[type="checkbox"].toggle').bootstrapToggle();
});

document.addEventListener("turbolinks:before-cache", function() {
	$('.bootstrap-select').each(function () {
		var select = $(this).find("select");
		$(this).replaceWith(select);
	});
	$('select').selectpicker();
});
