document.addEventListener("keydown", function(e) {

  switch(e.keyCode) {
    case 27:
      $('.alert .close').click();
      $("#flash_messages_modal").hide();
    break;
  }

});
