class WorkerStatusPoller {
  constructor() {
    this.timeoutId = null;
    this.domElement = null;
  }

  setDomElement(domElement) {
    return this.domElement = domElement;
  }

  poll() {
    const status = this.domElement.data('status');
    if (status !== "complete") {
      if (this.timeoutId) { clearTimeout(this.timeoutId); }
      return this.timeoutId = setTimeout(this.request, 1000, this); 
    }
  }

  request(poller) {
    return $.get(poller.domElement.data('url'));
  }
};
    
const workerStatusPoller = new WorkerStatusPoller;

const armTimer = function() {
  const domElement = $('#progress-bar');
  if (domElement.length) {
    disableAjaxLoader();
    workerStatusPoller.setDomElement(domElement);
    return workerStatusPoller.poll(); 
  } else {
    $('#validate_payment_report').prop('disabled', false);
    return enableAjaxLoader();
  }
};

$.fn.pollWorkerStatus = () => armTimer();

$(document).on('turbolinks:load', armTimer);    
         