function initTabClicks() {

    $('#tabby').on("click", "a.tabby-link", function (e) {
      e.preventDefault();
      $(this).tab('show');
      history.pushState({}, "", this.href);
      updateCurrentBreadcrumb();
  });

}

document.addEventListener("turbolinks:load", initTabClicks);


(function($) {

    $.extend({
        loadTabFromHash: loadTabFromHash
    });

    function loadTabFromHash(defaultHashState) {
      var hash = location.hash;

      if(hash == ''){
        hash = defaultHashState;  
      }

      $('#tabby a[href="' + hash + '"]').tab('show');
    };

})(jQuery);    