require('autonumeric')

import AutoNumeric from 'autonumeric';

const ready = function() {
  $( 'input[data-role=money]' ).each(function( index ) {
    new AutoNumeric(this);
  });

  

  $('#talent-payments-modal-window').on('show.bs.modal', function(event) {
    const button = $(event.relatedTarget);
    const recipient = button.data('artist-name');
    const payments = button.data('payments');
    const subtotal = button.data('subtotal');
    const total = button.data('total');

    const modal = $(this);
    modal.find('.modal-title').text(recipient);
    $("#modal-table tbody").html("");

    for (let p of Array.from(payments)) {
      $('#modal-table tbody').append('<tr><td>' + p.event_type + '</td><td>' + p.name + '</td><td>' + p.pay_type + '</td><td>' + p.date + '</td><td>' + p.amount + '</td><td>' + p.deducted_amount + '</td><td>' + p.status + '</td><td>' + p.creator + '</td></tr>');
    }

    return $('#modal-table tfoot').html('<tr><td></td><td></td><td></td><td></td><td><strong>' + subtotal  + '</strong></td><td><strong>' + total + '</strong></td><td></td><td></td></tr>');
  });  


  $("#payments_report_find").off().on("click", e => submitFormInFormat("#payments_report_form", "html"));

  $("#payments_export_iif").off().on("click", e => submitFormInFormat('#payments_report_form', 'xlsx')); 


  $("#dwolla_funding_source_option").on("change", function(e) {
    if ($(this).val().length > 0) {
      return $("#dwolla_send_now").prop('disabled', false);
    } else {
      return $("#dwolla_send_now").prop('disabled', true);
    }
  });
    

  $("#dwolla_send_now").off().on("click", function(e) { 

    const result  = confirm("Are you sure you want to send these payments on Dwolla NOW?");

    if (result) {      
      return submitFormInFormat("#payments_report_form", "html", {"dwolla_funding_source_id" : $("#dwolla_funding_source_option").val()}, "/payments/send_now"); 
    }

    return false;
  });

  $("#corporate, #wedding, #cruise, #other").off().click(function() {
    const {
      checked
    } = this;
    const selector = '[data-date-selector-for=' + this.id + ']';
    return $(selector).each(function() {
      return $(this).prop('disabled', !checked);
    });
  });

  $("#update-payment-collection").off().on("click", function(e) {
    const checked = $('input:checkbox.selectable:checked').map(function() { return this.value; }).get();

    if (checked.length > 0) {
      $("#payment_ids").val(checked);
      const form = $("#update_payment_collection_form");
      $(form).submit();    
    }
    
    return false;
  });   

  return $("#payment_status").off().on("change", function(e) {
    if ($(this).val().length > 0) {
      return $("#update-payment-collection").prop('disabled', false);
    } else {
      return $("#update-payment-collection").prop('disabled', true);
    }
  });
};
      

var submitFormInFormat = function(form_id, format, params, override_action=null) {
  if (params == null) { params = {}; }
  return $(document).submitFormInFormat(form_id, format, params, override_action);
};
   

$(document).on('turbolinks:load', ready);
