document.addEventListener("turbolinks:load", function() {
	popoverInit();
	galleryImageFlag();
});


function popoverInit() {
	$('body').on('click', function (e) {
	    $('[data-toggle=popover]').each(function () {
	        // hide any open popovers when the anywhere else in the body is clicked
	        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
	            $(this).popover('hide');
	        }
	    });
	});
	
  $('[data-toggle="popover"]').popover({
  	html: true,
  	container: 'body',
  	content: function(){return '<img src="'+$(this).data('img') + '" width="585" height="288" />';}
  });
}


function galleryImageFlag(){
  $(".gallery-image .fa-flag").click(function(event){
  	event.preventDefault();

  	$.ajax({
  	  type: "GET",
  	  url: "/flag-photo",
  	  data: {
  	    gallery_image: $(this).data('flag')
  	  }
  	});    

  })
}