const ready = function() {
  $('#to_').tokenInput('/talent/sms_search.json', {
    crossDomain: false,
    theme: 'facebook',
    preventDuplicates: true
  }
  );

  $('#conflict-modal-window').off().on('show.bs.modal', function(event) {
    const button = $(event.relatedTarget);
    const events_user = button.data('events-user');
    $(".modal-title").html("Conflict on " + events_user["date"]);    
    $("#conflict-modal-body").html("<h4>" + events_user["description"] + "</h4><hr>");
    if (events_user["city"] !== undefined) {
      $("#conflict-modal-body").append("<p>"+ events_user["city"] + "</p>"); 
    }
    if (events_user["time"] !== undefined) {
      return $("#conflict-modal-body").append("<p>"+ events_user["time"] + "</p>");
    }
  });

  $('#myModal').off().on('show.bs.modal', function(event) {});    
      

  $('#talentNotesModal').on('show.bs.modal', function(event) {
    const button = $(event.relatedTarget);
    $(this).find('.talent-notes-title').html(button.data('title'));
    return $(this).find('.talent-notes').html(button.data('content'));
  });

  $("#export_xlsx").off().on("click", function(e) {
    submitFormInFormat("#pay_talent_report_form", "xlsx");
    return false;
  });

  $("#export_csv").off().on("click", function(e) {
    submitFormInFormat("#pay_talent_report_form", "csv");
    return false;
  });

  $("#report_find").off().on("click", function(e) {
    submitFormInFormat("#pay_talent_report_form", "html");
    return false;
  });    

  $("#fm_events").off().on("click", function(e) {
    const form = $("#pay_talent_report_form");
    form.attr("action", "/talent/fm_validate_events");
    return form.submit();
  });

  $("#fm_event_talent").off().on("click", function(e) {
    const form = $("#pay_talent_report_form");
    form.attr("action", "/talent/fm_validate_event_talent");
    return form.submit();
  });

  return $("#fm_event_talent_cleanup").off().on("click", function(e) {
    const form = $("#pay_talent_report_form");
    form.attr("action", "/talent/fm_cleanup_event_talent");
    return form.submit();
  }); 
};

  //$('.panel').off().on 'hidden.bs.collapse', '.collapsing', ->
  //  collapse_element = "[data-target='#" + $(this).attr('id') + "']"
  //  $(collapse_element).text("Show")

  //$('.panel').on 'shown.bs.collapse', '.in', ->
  //  collapse_element = "[data-target='#" + $(this).attr('id') + "']"    
  //  $(collapse_element).text("Hide")    

var submitFormInFormat = function(form_id, format) {
  const form = $(form_id);
  const action = form.attr("action").replace(/\.[^/.]+$/, "");
  form.attr("action", action + "." + format);
  return form.submit();
};

$(document).on('turbolinks:load', ready);