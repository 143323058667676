document.addEventListener("turbolinks:load", function() {
  $('#selectable_records').off();
  selectAllInit();
});

function selectAllInit() {
  $('.select-all').click(function(event) {
    return $('input[type="checkbox"]').not('.disabled').prop('checked', $(this).prop('checked'));
  });

  $('#selectable_records').on('change', 'table tr input:checkbox', function(e) {
    const checked = $('input:checkbox.selectable:checked').length;
    

    if(checked == 0) {
      $(".selected-action-link").attr('disabled', true);
      $(".selectable-show").addClass("hidden");  
      $(".selectable-hide").removeClass("hidden");  
    }
    else {
      $(".selected-action-link").attr('disabled', false);
      $(".selectable-show").removeClass("hidden");  
      $(".selectable-hide").addClass("hidden");        
    }

    return $("#selected_records_text").html(checked + " records selected.");
  });

  $('#selectable_records').on('change', 'table tr input:checkbox.selectable-exclusive', function(e) {
    if( $(this).is(":checked") ) 
    {
      $("input:checkbox:checked[class^='selectable']").not(this).prop('checked', false); 
      $(".selected-action-link").attr('disabled', false);
    }
    else {
      $(".selected-action-link").attr('disabled', true);
    }
  });    
}

function getSelectedValues() {
  var checkedValues = $("input:checkbox:checked[class^='selectable']").map(function() {
      if (this.value != 'on'){
        return this.value;
      }
    }).get();

  return checkedValues;  
}

function clearSelectable() {
  $("input:checkbox:checked[class^='selectable']").prop('checked', false); 
  $(".selected-action-link").attr('disabled', true);  
}

window.getSelectedValues = getSelectedValues;
window.clearSelectable = clearSelectable;
window.selectAllInit = selectAllInit;
