function EventModal(options) {
  this.modal = options.modal;
  this.modalContent = options.modalContent;
  this.addItemBtn = options.addItemBtn;
  this.listTable = options.listTable;
  this.inputName = options.inputName;
  this.submitFormHandler = options.submitFormHandler;

  this.updateListData = function($template, data) {
    $template.find('.data').each(function(index, value) {
      $(value).text(data[index]);
    });
    return $template.removeClass('row-template hidden');
  }

  this.listInput = function(name, id) {
    return '<input type="hidden" name="' + name + '" value="' + id + '">';
  }

  this.init = function () {
    var $modal = $(this.modal);
    var $modalContent = $(this.modalContent);
    var $listTable = $(this.listTable);
    var updateListData = this.updateListData;
    var listInput = this.listInput;
    var inputName = this.inputName;
    var addItemBtn = this.addItemBtn;
    var submitFormHandler = this.submitFormHandler;

    $(this.modalContent).find('tr.selectable').click( function(e) {
      var selectedClass = 'row-selected';
      if ($(this).hasClass(selectedClass)) {
        $(this).removeClass(selectedClass);
      } else {
        $(this).addClass(selectedClass);
        $(addItemBtn).removeClass('disabled');
      }
    });

    $(this.addItemBtn).click(function() {
      var $selectedRow = $modalContent.find('tr.row-selected');
      $selectedRow.each(function(index, item) {
        var $item = $(item);
        var data = $item.find('td').map(function(index, value){
          return $(value).text();
        });
        var $template = $listTable.find('.row-template').clone();
        updateListData($template, data).appendTo($listTable);
        $template.append(listInput(inputName, $item.attr('id')));
      });
      $modal.modal('hide');
      eval(submitFormHandler)();
    });
  }.bind(this);
}

window.EventModal = EventModal;



