var addEvents, addTalent, inviteTalent, ready, removeEvents, removeTalent, bookEvents;

ready = function() {
  $(".campaign_row").click(function() {
    return Turbolinks.visit($(this).attr("data-link"), {
      action: "replace"
    });
  });

  $("#campaign-dropdown").off().on("click", "a", function(e) {
    var checkedValues, talent_action;
    e.preventDefault();
    checkedValues = getSelectedValues();

    if (checkedValues.length > 0) {
      talent_action = $('input:checkbox:checked').attr("id") === "bulk_talent_ids_";

      switch ($(this).attr("id")) {
        case "add_selected":
          if (talent_action) {
            return addTalent(checkedValues);
          } else {
            return addEvents(checkedValues);
          }
          break;
        case "remove_selected":
          if (talent_action) {
            return removeTalent(checkedValues);
          } else {
            return removeEvents(checkedValues);
          }
          break;
        case "invite_selected":
          if (talent_action) {
            return inviteTalent(checkedValues);
          }
          else {
            return inviteToEvents(checkedValues);
          }
      }
    }
  });

  $(".offer-content").on("click", ".mobile-cancel-link", function(e) {
    var form = $(this).data('dismiss');
    $(form).html("");
    return false;
  });

  $("#campaign-confirm-talent-window").off().on("click", ".modal-talent-row", function(event) {
    row = $(this);
    $(".modal-talent-row").removeClass("modal-talent-row-selected");
    row.addClass("modal-talent-row-selected");
    $("#user_id").val(row.data('user-id'));
    enableAddUserSubmissionButtons();
    if(row.hasClass("modal-talent-row-conflict")) {
      $("#confirm_talent_modal_button").data("confirm", "This talent member has a conflict on the requested event date. Proceed?");
      $("#confirm_talent_modal_button").data("ok", "Confirm");
      $("#confirm_talent_modal_button").data("cancel", "Cancel");
    }
    else {
      $("#confirm_talent_modal_button").data("confirm", null);
    }

  });  

  $("#campaign-confirm-talent-modal-content").off().on("change", "#region-select, #distance-select", function(e) {

    if($(this).attr("id") == "distance-select") {
      selector = "#region-select";
    }
    else {
      selector = "#distance-select";
    }

    $(selector).prop('selectedIndex', -1);

    return false;
  });
};

addTalent = function(selected_talent) {
  $("#add_selected_talent_ids").val(selected_talent);
  return $("#add_talent_form").submit();
};

removeTalent = function(selected_talent) {
  var c;
  c = confirm("Remove selected talent?");
  if (c) {
    $("#remove_selected_talent_ids").val(selected_talent);
    return $("#remove_talent_form").submit();
  }
};

inviteTalent = function(selected_talent) {
  $("#invite_selected_talent_ids").val(selected_talent);
  return $("#invite_talent_form").submit();
};

addEvents = function(selected_events) {
  $("#add_selected_event_ids").val(selected_events);
  return $("#add_events_form").submit();
};

removeEvents = function(selected_events) {
  var c;
  c = confirm("Remove selected events?");
  if (c) {
    $("#remove_selected_event_ids").val(selected_events);
    return $("#remove_events_form").submit();
  }
};

inviteToEvents = function(selected_events) {
  $("#invite_selected_event_ids").val(selected_events);
  return $("#invite_events_form").submit();
};


confirmEventAvailability = function(campaignId, eventIds) {
  var url = "/campaigns/" + campaignId + "/event_availability";
  $.ajax({
        type: "GET",
        url: url,
        data: {
          event_ids: eventIds
        }
      }); 
};

bookEventsSetup = function() {

  $('.boxes label, .events-to-book label').off().click(function() {
    var campEvent = $(this).data('campaign-event');

    $('*[data-campaign-event=' + campEvent + ']').toggleClass('active-event')

    var checker = $('.custom-checkbox *[data-campaign-event=' + campEvent + ']')

    if(checker.prop('checked')){
      checker.prop('checked',false)
    }else{
      checker.prop('checked',true)
    }

    var bookedEventSize = $('.bookable-events label .active-event').size()

    var maxEvents = 1

    if(bookedEventSize > maxEvents){
      $('#book-events-button').addClass('disabled')
      $("#book-events-button, .event_confirm_btn").html('Only 1 Event Allowed')
      $('.btn-danger').addClass('disabled')
    }else if(bookedEventSize > 1){
      $("#book-events-button, .event_confirm_btn").html('Book ' + bookedEventSize + ' Events')
      $('#book-events-button').removeClass('disabled')
      $('.btn-danger').addClass('disabled')
    } else if(bookedEventSize > 0){
      $("#book-events-button, .event_confirm_btn").html('Book 1 Event')
      $('#book-events-button').removeClass('disabled')
      $('.btn-danger').addClass('disabled')
    } else {
      $("#book-events-button, .event_confirm_btn").html('Select an Event')
      $('#book-events-button').addClass('disabled')
      $('.btn-danger').removeClass('disabled')
    }
  })

  $(".btn-book-single-event").click(function(){
    var campaignId = [$(this).data('campaign-id')];
    var eventIds = [$(this).data('event-id')];
    confirmEventAvailability(campaignId, eventIds);
  })

  $("#book-events-button").click(function(){
    var eventIds = $('.events-to-book .active-event').map(function(){
      return $(this).data('campaign-event')
    }).get().join()
    alert('need to add campaign id!!!');
    confirmEventAvailability(eventIds);
  })  
}

enableAddUserSubmissionButtons = function() {
  $("#confirm_talent_modal_button").prop('disabled', false);
  $("#prep_invite_talent_button").removeAttr("disabled");  
}

disableAddUserSubmissionButtons = function() {
  $("#confirm_talent_modal_button").prop('disabled', true);
  $("#prep_invite_talent_button").attr("disabled", "disabled");  
}

$(document).on('turbolinks:load', ready);
