var startShown, endShown;

$.fn.initDatepickerStartAndEnd = function() {

  startShown = false;
  endShown = false;

  $('#start').datepicker().on("hide", function(e){
    setTimeout(function(){
      return startShown = false;
    }, 200);
  });

  $('#end').datepicker().on("hide", function(e){
    setTimeout(function(){
      return endShown = false;
    }, 200);
  });  

  function toggleDatePicker(status, selector) {

    if(status == false) {
      $(selector).datepicker( "show" );
      return true;
    }

    return false;
  }

  $('#start_button').on('click', function(e){ 
    startShown = toggleDatePicker(startShown, '#start');
  });

  $('#end_button').click(function(){ 
    endShown = toggleDatePicker(endShown, '#end');
  }); 
}