function is_usZipCode(str)
{
  regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;

  if (regexp.test(str))
  {
    return true;
  }
  else
  {
    return false;
  }
}

function showHasError(elementWrapper) {
  $(elementWrapper).addClass("has-error");
  $(elementWrapper).children("input").first().focus();
}

function displayErrorMessage(message) {
  $(".errors").append(`<div class='alert alert-danger'>${message}<button class='close hide-alert-bg'  data-dismiss='alert' type='button'><span>x</span></button></div>`);   
  return false;
}  

function isValidMonth(expiryMonth) {
  return (expiryMonth >= 1 && expiryMonth <= 12);
};


function isExpiryValid(month, year) {
  var today = new Date();
  var currentMonth = (today.getMonth() + 1);
  var currentYear = "" + today.getFullYear();

  if(("" + year).length == 2) {
    year = currentYear.substring(0, 2) + "" + year;
  }

  currentMonth = parseInt(currentMonth);
  currentYear = parseInt(currentYear);
  month = parseInt(month);
  year = parseInt(year);

  return isValidMonth(month)
    && ((year > currentYear) || (year == currentYear && month >= currentMonth));
};


function clearCardErrors() {
  $(".errors").html("");
  $(".has-error").removeClass("has-error");
}

$.fn.validateCardForm = function() {

  clearCardErrors();

  var cardNumber = $('#my-card').CardJs('cardNumber');
  
  if(cardNumber.length < 14) {
    showHasError($(".card-number-wrapper").first());  
    return displayErrorMessage("Card number is invalid.");
  }   

  var name = $('#my-card').CardJs('name'); 

  if(name.length == 0) {
    showHasError($(".name-wrapper").first());  
    return displayErrorMessage("Name on the card is required.");
  }        

  var expiryMonth = $('#my-card').CardJs('expiryMonth');
  var expiryYear = $('#my-card').CardJs('expiryYear');


  if(isExpiryValid(expiryMonth, expiryYear) == false) {
    showHasError($(".expiry-wrapper").first());  
    return displayErrorMessage("Expiration date is invalid.");
  }

  var cvc = $('#my-card').CardJs('cvc'); 

  if(cvc.length == 0) {
    showHasError($(".cvc-wrapper").first());  
    return displayErrorMessage("CVC is required.");
  }     


  var zipCode = $(".zip-code").first().val();

  if(is_usZipCode(zipCode) == false) {   
    showHasError($(".zip-code-wrapper").first());  
    return displayErrorMessage("Zip Code is invalid.");
  } 

  return true;
}
