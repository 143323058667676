(function() {
  var ready, submitFormForSelection;

  $.fn.eventsInit = function() {
    $('.event_row').click(function() {
      return window.location = $(this).attr('data-link');
    });
    $(document).on('click', '.invite-talent', function() {
      var url;
      if ($("#user_id").val().length > 0) {
        disableAddUserSubmissionButtons();
        $(".modal").modal("hide");
        url = "/events/" + $("#event_id").val() + "/prepare_invite";
        $.ajax({
          type: 'GET',
          url: url,
          data: {
            user_id: $("#user_id").val()
          }
        });
      }
      return false;
    });
    $(document).on('click', '#invite-talent-button', function() {
      return $("#invite_talent_form").submit();
    });
    return false;
  };

  $.fn.userViewedEvent = function(userId, eventId) {
    var url;
    url = "/events/" + eventId + "/view";
    return $.ajax({
      type: 'POST',
      url: url,
      data: {
        user_id: userId
      }
    });
  };

  submitFormForSelection = function(form) {
    var checked;
    checked = $('input:checkbox.selectable:checked').map(function() {
      return this.value;
    }).get();
    if (checked.length > 0) {
      $("#event_ids").val(checked);
      $(form).submit();
    }
    return false;
  };

  ready = function() {
    $("#find-venue-modal").off().on('click', function(e) {
      var eventId, state;
      eventId = $(this).data("event-id");
      state = $(this).data("state");
      $.ajax({
        type: 'GET',
        url: "/venues",
        data: {
          event_id: eventId,
          state: state
        },
        success: function(resp) {
          $('#findVenueModal').modal('show');
        }
      });
      return false;
    });
    $('#findVenueModal').off().on('click', '.modal-venue-row', function(event) {
      var row;
      row = $(this);
      $('.modal-venue-row').removeClass('modal-venue-row-selected');
      row.addClass('modal-venue-row-selected');
      $('#event_venue_id').val(row.data('venue-id'));
    });
    return $("#bulk_update_events_option").off().on("change", function(e) {
      return submitFormForSelection("#bulk_update_events_form");
    });
  };

  $(document).on('turbolinks:load', ready);

}).call(this);