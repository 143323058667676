function setFormAction(form_id, format, override_action) {

  var action = null;
  const form = $(form_id);

  if (override_action) {
    action = override_action;
  } else {
    action = form.attr("action").replace(/\.[^/.]+$/, "");
  }

  form.attr("action", action + "." + format);

  return form;
}


$.fn.submitFormInFormat = function(form_id, format, params, override_action) {
  
  if (params == null) { params = {}; }

  const form = setFormAction(form_id, format, override_action);
  
  $.each(params, function( key, value ) {
    $('<input />').attr('type', 'hidden').attr('name', key).attr('value', value).appendTo(form);
  });

  return form.submit();
}; 



$.fn.resetFormFormat = function(form_id, format) {
  setFormAction(form_id, format);
}

document.addEventListener("turbolinks:load", function() {

  $(".expandable-form .expandable-form-addon").click(function(e){
    $('#advancedFilterOptions').collapse('toggle');
  });

  $(document).on("submit", "form", function() {  
    $('.btn-disable-on-submit').attr('disabled', true);
  });  
  
});
