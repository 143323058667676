function dashboardInit(){
  galleryImageInit();
  mixInit();
  completePageCookie();
  setBgUrl();
  paperclipCopy();
  inputToggle();


  $('.panel-toggle').click(function(e){
    if ( $(e.target).hasClass('close-panel') ){
      $(this).find('.fa').toggleClass('rotated')
      $(this).children().last().slideToggle()
    }
  })

  $('.event').click(function(){ 
    Turbolinks.visit($(this).attr('href'))
  })

  $('.event-venue-fetch').click(function(){
    if(!$(this).hasClass('fetched')){
      $.ajax({
        type: "GET",
        url: "/venue-events.js",
        data: {
          venue: $(this).data('venue-info'),
          event_scope: $('.event-filters .active').attr("href").split('=')[1].split('&')[0]
        }
      });

      $(this).addClass('fetched')
    }
  })


  $("input[data-role=tagsinput], select[multiple][data-role=tagsinput]").tagsinput();


  $('.event-state-fetch').click(function(){

    if(!$(this).hasClass('fetched')){
      $.ajax({
        type: "GET",
        url: "/venue-events.js",
        data: {
          state: $(this).data('venue-info'), 
          event_scope: $('.event-filters .active').attr("href").split('=')[1].split('&')[0]
        }

      });

      $(this).addClass('fetched')
    } 
  })
}

function setTooltip(btn, message) {
  $(btn).tooltip('hide')
    .attr('data-original-title', message)
    .tooltip('show');
}

function hideTooltip(btn) {
  setTimeout(function() {
    $(btn).tooltip('hide')
    .attr('data-original-title', "");
  }, 1000);
}

function paperclipCopy(){

  var clipboard = new Clipboard(".clip_button");

  clipboard.on('success', function(e) {
    setTooltip(e.trigger, 'Copied!');
    hideTooltip(e.trigger);
  });

  clipboard.on('error', function(e) {
    setTooltip(e.trigger, 'Failed!');
    hideTooltip(e.trigger);
  });  
}

function inputToggle(){
  $('.btn-number').click(function(e) {
    e.preventDefault();

    var fieldName = $(this).attr('data-field');
    var type = $(this).attr('data-type');
    var input = $("input[name='" + 'equipment[' + fieldName + ']' + "']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
      if (type == 'minus') {
        var minValue = parseInt(input.attr('min'));
        if (!minValue) minValue = 0;
        if (currentVal > minValue) {
          input.val(currentVal - 1).change();
        }
        if (parseInt(input.val()) == minValue) {
          $(this).attr('disabled', true);
        }

      } else if (type == 'plus') {
        var maxValue = parseInt(input.attr('max'));
        if (!maxValue) maxValue = 10;
        if (currentVal < maxValue) {
          input.val(currentVal + 1).change();
        }
        if (parseInt(input.val()) == maxValue) {
          $(this).attr('disabled', true);
        }

      }
    } else {
      input.val(0);
    }
  });
  $('.input-number-inc').focusin(function() {
    $(this).data('oldValue', $(this).val());
  });
  $('.input-number-inc').change(function() {

    var minValue = parseInt($(this).attr('min'));
    var maxValue = parseInt($(this).attr('max'));
    if (!minValue) minValue = 0;
    if (!maxValue) maxValue = 10;
    var valueCurrent = parseInt($(this).val());

    var name = $(this).attr('name');
    if (valueCurrent >= minValue) {
      $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr('disabled')
    } else {
      alert('Sorry, the minimum value was reached');
      $(this).val($(this).data('oldValue'));
    }
    if (valueCurrent <= maxValue) {
      $(".btn-number[data-type='plus'][data-field='" + name + "']").removeAttr('disabled')
    } else {
      alert('Sorry, the maximum value was reached');
      $(this).val($(this).data('oldValue'));
    }
  });

  $(".input-number-inc").keydown(function(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  });
}


function geoCompleteWithMap(){

  var mapStyles = [{"elementType":"geometry","stylers":[{"hue":"#ff4400"},{"saturation":-68},{"lightness":-4},{"gamma":0.72}]},{"featureType":"road","elementType":"labels.icon"},{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"hue":"#0077ff"},{"gamma":3.1}]},{"featureType":"water","stylers":[{"hue":"#00ccff"},{"gamma":0.44},{"saturation":-33}]},{"featureType":"poi.park","stylers":[{"hue":"#44ff00"},{"saturation":-23}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"hue":"#007fff"},{"gamma":0.77},{"saturation":65},{"lightness":99}]},{"featureType":"water","elementType":"labels.text.stroke","stylers":[{"gamma":0.11},{"weight":5.6},{"saturation":99},{"hue":"#0091ff"},{"lightness":-86}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"lightness":-48},{"hue":"#ff5e00"},{"gamma":1.2},{"saturation":-23}]},{"featureType":"transit","elementType":"labels.text.stroke","stylers":[{"saturation":-64},{"hue":"#ff9100"},{"lightness":16},{"gamma":0.47},{"weight":2.7}]}];

  var options = {
    map: ".map_canvas",
    mapOptions: {
      styles: mapStyles,
      draggable: true,
      scrollwheel: false
    },
    types: ['geocode', 'establishment']
  };

  $("#geocomplete").geocomplete(options);
  $("#geocomplete").trigger("geocode");

  //var address = $("#geocomplete").val();


  //if(address.length > 0)
  //{
    //$("#geocomplete").geocomplete("find", address);
    //$("#geocomplete").trigger("geocode");
  //}
  
  //$(window).load(function(){
  //  $('#geocomplete').val('')
  //})

  $("#geocomplete").on('keyup', function () {
    //$("#map-container").removeClass("hidden");    
  });

  $("#geocomplete").on('blur', function () {
    if($(window).width() >  991){
      $('#map-container').css('position','relative')
      var map = $(".map-div");
      TweenLite.to(map, 0, {visibility: 'visible', position: 'relative'});
      $("#details-before").css({
        'width': '49%',
        'margin-right': '1%',
        'float': 'left'
      });
    }
  });

  $("#geocomplete").bind("geocode:result", function(event, result){
    //console.log(result);
  });
}

function galleryImageInit(){
  $("#gallery_selector").on('change',function (){
    if($(this)[0].files.length > 0){
      $("#gallery_upload_submit").fadeIn()
    }else{
      $("#gallery_upload_submit").fadeOut()
    }
  });

  $('.edit-photo').click(function(){
    $(this).next().fadeToggle()
  })

  $('.update-gi-btn, .update-audio-btn').click(function(){
    var gparent = $(this).parent().parent()
    gparent.fadeOut('slow')
    gparent.prev().css('color','green')
    setTimeout(function(){
      gparent.prev().css('color','black')
    }, 2000)
  })
}

function mixInit(){
  $("#mix_selector").on('change',function (){
    if($(this)[0].files.length > 0){
      $("#mix_upload_submit").fadeIn()
    }else{
      $("#mix_upload_submit").fadeOut()
    }
  });

  $('.edit-mix').click(function(){
    $(this).next().fadeToggle()
  })

  $('.update-mix-btn').click(function(){
    var gparent = $(this).parent().parent()
    gparent.fadeOut('slow')
    gparent.prev().css('color','green')
    setTimeout(function(){
      gparent.prev().css('color','black')
    }, 2000)
  })
}

function completePageCookie() {
  $("#completed-account").click(function(){
    Cookies.set('accountCompleted', "true", { expires: 5 });
    $('.onboarding-steps-container').fadeToggle()
    $(this).toggleClass('rotated')
  })

  if( Cookies.get('accountCompleted') == 'true' ){
    $(".onboarding-steps-container").hide()
    $("#completed-account").addClass('rotated')
  }
}

function setBgUrl(){
  $('.bg-select').click(function(){
    $('.bg-select').removeClass('bg-active')
    $(this).addClass('bg-active')
    $('#bg_url').val($(this).data('img-url'))
  })
}

window.dashboardInit = dashboardInit;
window.geoCompleteWithMap = geoCompleteWithMap;
