var talentCollection = null;

function getTalentCollection() {
  if(talentCollection == null) {

    var prefetchUrl = encodeURI("/talent/sms_search.json");

    talentCollection = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {url: prefetchUrl, cache: false},
    });

    talentCollection.initialize();
  }

  return talentCollection;
}

$.fn.initTalentSearchInput = function(domElement) {
  $(domElement).tagsinput({
      itemValue: 'id',
      itemText: 'name',
      freeInput: false,
    typeaheadjs: {
      name: 'talent',
      displayKey: 'name',
      source: getTalentCollection().ttAdapter()
    }
  });   
}