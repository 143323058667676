var outerContainer = "#remote-container";
var validationStates = ["has-warning", "has-error", "has-success"];

var ajaxLoaderEnabled = true;

function enableAjaxLoader() {
  ajaxLoaderEnabled = true;
}

function disableAjaxLoader() {
 ajaxLoaderEnabled = false;
}

function readValidationStates(readFromElement) {
  validationStates.forEach( function(state) {

    var fieldWithValidationState = $(readFromElement).data(state);

    if(fieldWithValidationState) {      
      appendValidationState(state, fieldWithValidationState);
    } 
    
  });  
}

function applyValidationStates() {
  validationStates.forEach( function(state) {
   
    var fieldWithValidationState = $(outerContainer).data(state);

    if(fieldWithValidationState) {
      $(outerContainer).data(state, null);
      $("input[name='" + fieldWithValidationState + "']").closest(".form-group").addClass(state);
    }    

  });  

}

function appendValidationState(validationState, formField)
{
  $(outerContainer).data(validationState, formField);
}

function showFormFieldError(formField) {
  appendValidationState("has-error", formField);
  applyValidationStates();
}

function clearFormFieldErrors() {
  $(".has-error").removeClass("has-error");
}

function handleAjaxDismissModal() {
  $( ".dismiss-on-ajax-start" ).modal("hide");
}

$( document ).on( 'ajaxStart ajax:before', function() {
  handleAjaxDismissModal();

  if(ajaxLoaderEnabled == false) {
    return;
  }   

  $('#loader-overlay').show();  
});

$( document ).on( 'ajaxComplete ajax:complete', function(event){  

  setTimeout(function(){
    if(ajaxLoaderEnabled == false) {
      return;
    }       

    $('#loader-overlay').fadeOut() 
  },250)
});

document.addEventListener("turbolinks:load", function() {

  $(".btn-emphasize-field-in-modal").click(function(){
    readValidationStates(this);
  });  

  $( outerContainer ).on('shown.bs.modal', '.modal', function(){
    applyValidationStates();
  });  

  $('.trigger-load').click(function(){
    $('#loader-overlay').show()
  })  

});

$.fn.showLoader = function() {
  $('#loader-overlay').show();
};

$.fn.hideLoader = function() {
  $('#loader-overlay').fadeOut();
};

$.fn.dismissModal = function() {
  $(".modal").removeClass('fade');
  $(".modal").modal('hide');
  $('.modal-backdrop').remove();
};

window.clearFormFieldErrors = clearFormFieldErrors;
window.showFormFieldError = showFormFieldError;
window.disableAjaxLoader = disableAjaxLoader;
window.enableAjaxLoader = enableAjaxLoader;
