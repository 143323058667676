import {Spinner} from 'spin.js';

const main_spinner_opts = {
  lines: 9, // The number of lines to draw
  length: 0, // The length of each line
  width: 9, // The line thickness
  radius: 14, // The radius of the inner circle
  corners: 1, // Corner roundness (0..1)
  rotate: 0, // The rotation offset
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: "#000", // #rgb or #rrggbb
  speed: 1, // Rounds per second
  trail: 60, // Afterglow percentage
  shadow: false, // Whether to render a shadow
  hwaccel: false, // Whether to use hardware acceleration
  className: "spinner", // The CSS class to assign to the spinner
  zIndex: 2e9, // The z-index (defaults to 2000000000)
  top: "8px", // Top position relative to parent in px
  left: "auto" // Left position relative to parent in px
};

const page_spinner_opts = {
  lines: 9, // The number of lines to draw
  length: 0, // The length of each line
  width: 4, // The line thickness
  radius: 7, // The radius of the inner circle
  corners: 1, // Corner roundness (0..1)
  rotate: 0, // The rotation offset
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: "#000", // #rgb or #rrggbb
  speed: 1, // Rounds per second
  trail: 60, // Afterglow percentage
  shadow: false, // Whether to render a shadow
  hwaccel: false, // Whether to use hardware acceleration
  className: "spinner", // The CSS class to assign to the spinner
  zIndex: 2e9, // The z-index (defaults to 2000000000)
  top: "16px", // Top position relative to parent in px
  left: "auto" // Left position relative to parent in px  
};

let spinner = null;

jQuery(() => $("#search_form").submit(function() {
    $('.SearchMessage').hide();
    if (spinner) { spinner.stop(); }
    const target = document.getElementById("wait");
    return spinner = new Spinner(main_spinner_opts).spin(target);
}));

window.Helpers = {
    stopSpinning() {
        return spinner.stop();
    },

    startPageSpinning() {
        if (spinner) { spinner.stop(); }
        const target = document.getElementById("wait_page");
        return spinner = new Spinner(page_spinner_opts).spin(target);
    }  
};

jQuery(() => $("#records").tooltip({selector: "a[data-toggle=tooltip]"}));

const calendar_spin_ops = {
  lines: 13, // The number of lines to draw
  length: 20, // The length of each line
  width: 10, // The line thickness
  radius: 30, // The radius of the inner circle
  corners: 1, // Corner roundness (0..1)
  rotate: 0, // The rotation offset
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: "#000", // #rgb or #rrggbb
  speed: 1, // Rounds per second
  trail: 60, // Afterglow percentage
  shadow: false, // Whether to render a shadow
  hwaccel: false, // Whether to use hardware acceleration
  className: "spinner", // The CSS class to assign to the spinner
  zIndex: 2e9, // The z-index (defaults to 2000000000)
  top: "auto", // Top position relative to parent in px
  left: "auto" // Left position relative to parent in px    
};

let calendar_spin = null;

jQuery(function() {
    if (calendar_spin) { calendar_spin.stop(); }
    const target = document.getElementById("calendar_spin");
    return calendar_spin = new Spinner(calendar_spin_ops).spin(target);
});  
