function refreshPage() {    
  return location.reload(true);
}

function getCookie( name ) {
  var parts = document.cookie.split(name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
}

function expireCookie( cName ) {
    document.cookie = 
        encodeURIComponent(cName) + "=deleted; expires=" + new Date( 0 ).toUTCString();
}

function setFormToken() {
    var downloadToken = new Date().getTime();
    document.getElementById( "downloadToken" ).value = downloadToken;
    return downloadToken;
}

var downloadTimer;
var attempts = 30; 

function downloadComplete() {
  window.clearInterval( downloadTimer );
  expireCookie( "downloadToken" );
  attempts = 30;
  $(document).hideLoader();
  refreshPage();
}

function downloadStart() {
    
    $(document).showLoader();

    var downloadToken = setFormToken();

    downloadTimer = window.setInterval( function() {
        var token = getCookie( "downloadToken" );

        if( (token == downloadToken) || (attempts == 0) ) {
            downloadComplete();
        }

        attempts--;
    }, 1000 );

    return downloadToken;
}

$.fn.ensureDownloaded = function() {
  return downloadStart();
};