/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "core-js/stable"
import "regenerator-runtime/runtime"

import Rails from '@rails/ujs';
Rails.start();
window.Rails = Rails;

require("turbolinks").start()

require("src/add_jquery")

require('jquery.geocomplete')
require("jquery-tokeninput")

require('jquery-ui-dist/jquery-ui.js');

window.Cookies = require("js.cookie.js")

require ("bootstrap")
require ("bootstrap-toggle")
require ("bootstrap-tagsinput")
require ("bootstrap-select")
require ("bootstrap-datepicker")

require('grizdy-1.1.min')

require ("typeahead.js")
window.Bloodhound = require("typeahead.js/dist/bloodhound.min.js")

window.Clipboard = require("clipboard")

require("trix")
require("@rails/actiontext")

require("card-js")
require("moment")
require("combodate.js")

require("recurring-select")

require("@nathanvda/cocoon")

require('src/soundmanager')
require('src/global')
require('src/breadcrumbs')
require('src/events')
require('src/dashboard')
require('src/datepicker_start_and_end')
require('src/ajax_modal')
require('src/bind_dates_and_times')
require('src/card')
require('src/contact_select')
require('src/contracts')
require('src/currency')
require('src/ensure_downloaded')
require('src/event_modal')
require('src/forms')
require('src/keypress')
require('src/params')
require('src/profile')
require('src/selectable')
require('src/tabs')
require('src/tags')
require('src/talent_search')
require('src/visible')
require('src/campaigns')
require('src/bootstrap_form_editable')
require('src/worker_status_poller')
require('src/talent')
require('src/payments')
require('src/media_files')
require('src/spinner')
require('src/events_users')
require('src/profile')

