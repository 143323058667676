var tagCollections = {};

function getTagCollection(context) {
  var tags = tagCollections[context];

  if(tags == null) {

    var prefetchUrl = encodeURI("/tags.json?context=" + context);

    tags = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {url: prefetchUrl, cache: false},
    });

    tags.initialize();

    tagCollections[context] = tags;
  }

  return tags;
}

$.fn.initTagsInput = function(domElement, context) {
  $(domElement).tagsinput({
    typeaheadjs: {
      name: 'tags',
      displayKey: 'name',
      valueKey: 'name',
      source: getTagCollection(context).ttAdapter()
    }
  });   
}