const ready = function() {
  $('.form-default-readonly .form-control').prop('readonly', true);
  $('.form-default-readonly input[type="checkbox"].toggle').bootstrapToggle('disable'); 

  $(document).on('click', '.enable-form-edits-button', function() {    
    enableFormEdits($(this).data("target"));
    return false;
  });

  $(document).on('click', '.disable-form-edits-button', function() {    
    const formId = $(this).data("target");
    disableFormEdits(formId);
    revertFormFields(formId);
    return false;
  }); 

  return $(".form-default-readonly").on('click', '.form-control[readonly]', function() {
    const form = $(this).closest('form');   
    return $(form).find(".enable-form-edits-button").first().effect("bounce", { times:3 }, 300);
  });
};


var enableFormEdits = function(form_id) {
  $( form_id + ' .form-save-cancel' ).removeClass('hidden');
  $( form_id + ' .form-edit' ).addClass('hidden');
  $( form_id + ' .form-control').prop('readonly', false);
  return $( form_id + ' input[type="checkbox"].toggle').bootstrapToggle('enable'); 
};

var disableFormEdits = function(form_id) {
  $( form_id + ' .form-save-cancel' ).addClass('hidden');
  $( form_id + ' .form-edit' ).removeClass('hidden');
  $( form_id + ' .form-control').prop('readonly', true);
  return $( form_id + ' input[type="checkbox"].toggle').bootstrapToggle('disable');  
};


var revertFormFields = form_id => $( form_id + ' [data-original-value]' ).each(function(index) {    
  const originalValue = $(this).data('originalValue');
  $(this).val(originalValue);
  return $(this).trigger("change");
});

const updateRevertableFormFields = form_id => $( form_id + ' [data-original-value]' ).each(function(index) {    
  const updatedValue = $(this).val(); 
  return $(this).data('originalValue', updatedValue);
});
       

const showFlashModalErrorMessage = function(modal,message) {
  const html = '<div class=\'profile-alert alert alert-danger\'>' + message + '<button class=\'close hide-alert-bg\' \'aria-label\'=\'Close\' \'data-dismiss\'=\'alert\' \'type\'=\'button\'><span \'aria-hidden\'=\'true\'>x</span></button></div>';
  $(modal).html(html);
  return $(modal).show();
};

$.fn.formEditableAjaxComplete = function(status, message) {
  if (status === "success") {
    return $('.form-default-readonly').each(function(index) {
      const formId = '#' + this.id;
      disableFormEdits(formId);
      return updateRevertableFormFields(formId);
    });
  } else {
    return showFlashModalErrorMessage("#flash_messages_modal", message);
  }
};

$(document).on('turbolinks:load', ready);

