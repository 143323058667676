document.addEventListener("turbolinks:load", function() {
  var scratchContacts = $("#contract_performing_company_manager_id").html();

  $("#contract_performing_company_id").on('change', function(){
    
    var company = $("#contract_performing_company_id :selected").text();
    var filter = "optgroup[label='" + company + "']";  
    var options = $(scratchContacts).filter(filter).html();
    if(options) {       
      $("#contract_performing_company_manager_id").html(options);
    }
    else {
      $("#contract_performing_company_manager_id").empty();
    }

    $('#contract_performing_company_manager_id').prepend($(scratchContacts)[0]);
    $('#contract_performing_company_manager_id').val(null);
    $("#contract_performing_company_manager_id").selectpicker('refresh');
  });
});