document.addEventListener("turbolinks:load", function() {
  bindDatesAndTimesInit();
});

function bindDatesAndTimesInit() {
  $('.bound-date').each (function(i, val) {
    var bindToDate = $(val).data("bind-to-date");

    if (bindToDate) {
      $(bindToDate).on("change", {bound_date: this}, function(event){
        lowerBoundSelector = this;
        upperBoundSelector = event.data.bound_date;
        lowerBound = new Date($(lowerBoundSelector).val());
        upperBound = new Date($(upperBoundSelector).val());

        if(lowerBound > upperBound) {
          if($(upperBoundSelector).data("provide-datepicker")) {
            $(upperBoundSelector).datepicker('setUTCDate', lowerBound);
          }
          else {
            $(upperBoundSelector).val($(lowerBoundSelector).val());  
          }
        }
      });
    }
  });
}

window.bindDatesAndTimesInit = bindDatesAndTimesInit;