function updateContactCollection(contactName, contactId) {
  var option = "<option selected='selected' value='" + contactId + "'>" + contactName + " </option>";
  $(".contact-select").append(option);
  $(".contact-select").selectpicker('refresh');
};

function updateContactEmailField(contactEmail) {
  $(".contact-email").val(contactEmail);
};

$.fn.addAndSelectContact = function(contactJson) {
  updateContactCollection(contactJson.name, contactJson.id);
  updateContactEmailField(contactJson.email);
};